import React from 'react'
import PropTypes from 'prop-types'

const displayName = 'CommonLoader'

const propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.object,
}

const LoadingComponent = ({isLoading, error}) => {
  if (isLoading) {
    return <div className="loading start-app"><div className="align"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div></div>
  }

  else if (error) {
    return <div>Desculpe, ocorreu um problema ao carregar a página.</div>
  }
  else {
    return null
  }
}

LoadingComponent.displayName = displayName
LoadingComponent.propTypes = propTypes

export default LoadingComponent
