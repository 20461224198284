import * as types from "../types";

const initialState = {
    ordersCurrent: [],
    ordersPast: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ORDERS_CURRENT:
            return { ...state, ordersCurrent: action.payload };

        case types.GET_ORDERS_PAST:
            return { ...state, ordersPast: action.payload };

        default:
            return { ...state };
    }
};

export default reducer;
