import React, {Component} from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import {Layout, Button, Icon} from "antd";
// import {Popover} from "antd";

import IntlMessages from "util/IntlMessages";

import languageData from "./languageData";
import { logout } from "../../../modules/auth/service";

const {Header} = Layout;

// const locale = {
//     languageId: 'portugues-brasil',
//     locale: 'pt',
//     name: 'Português-BR',
//     icon: 'br'
// };

class Topbar extends Component {

	static displayName = 'TopBar'

	static propTypes = {
		dispatch: PropTypes.func.isRequired
	}

	languageMenu = () => (
		<div className="gx-popover-lang-scroll">
			<ul className="gx-sub-popover">
				{languageData.map(language =>
					<li 
					className="gx-media gx-pointer" 
					key={JSON.stringify(language)} 
					onClick={(e) => console.log(language)}>
						
						<i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
						
						<span className="gx-language-text">{language.name}</span>

					</li>
				)}
			</ul>
		</div>
	);

	render() {

		return (
			<Header>
				
				<ul className="gx-header-notifications gx-ml-auto">
					
					{/* <li className="gx-language">
						<Popover placement="bottomRight" content={this.languageMenu()} trigger="click">
							<span className="gx-pointer gx-flex-row gx-align-items-center"><i
								className={`flag flag-24 flag-${locale.icon}`}/>
								<span className="gx-pl-2 gx-language-name">{locale.name}</span>
								<i className="icon icon-charvlet-down gx-pl-2"/>
							</span>
						</Popover>
					</li> */}

					<li>
						<Button type="Button" onClick={ () => this.props.dispatch( logout() ) }><IntlMessages id="topbar.logout"/><Icon type="logout"></Icon></Button>
					</li>

				</ul>
			</Header>

		);

	}

}


const mapStateToProps = state => {
	return {}
}

export default connect( mapStateToProps )(Topbar)