import * as types from "../types";

const initialState = {
    all: null,
    allToday: null,
    user: [],
    usersbyregion: {},
    quantity: {},
    quantityPast: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_USERS:
            return { ...state, all: action.payload };

        case types.GET_ALL_USERS_TODAY:
            return { ...state, allToday: action.payload };

        case types.GET_USER:
            return { ...state, user: action.payload };

        case types.GET_USER_BY_REGION:
            return { ...state, usersbyregion: action.payload };

        case types.GET_USER_BY_REGION_AGGREGATED:
            return { ...state, quantity: action.payload };

        case types.GET_USER_BY_REGION_AGGREGATED_PAST:
            return { ...state, quantityPast: action.payload };

        default:
            return { ...state };
    }
};

export default reducer;
