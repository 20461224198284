import * as moment from "moment";
import "moment/locale/pt-br";
import Cookies from "universal-cookie";

import HTTP from "../../../util/Http";
import User from "../../../models/user/User";

import * as types from "./action-types";

const cookies = new Cookies();

const initialState = Object.assign(
    {},
    { isAuthenticated: false },
    new User({})
);

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTH_CHECK:
            return checkAuth(state);

        case types.AUTH_LOGIN:
            return login(state, action.payload);

        case types.AUTH_LOGOUT:
            return logout(state);

        case types.AUTH_USER:
            return authUser(state, action.payload);

        default:
            return { ...state };
    }
};

function checkAuth(state) {
    state = Object.assign({}, state, {
        isAuthenticated: !!cookies.get("access_token")
    });

    if (state.isAuthenticated) {
        HTTP.defaults.headers.common["Authorization"] = `Bearer ${cookies.get(
            "access_token"
        )}`;
    }

    return state;
}

function authUser(state, payload) {
    let expires = moment()
        .add(20, "days")
        .format();

    expires = new Date(expires);

    const token = HTTP.defaults.headers.common.Authorization.substring(7);

    cookies.set("access_token", token, {
        path: "/",
        expires,
    });

    return { ...state, ...payload, isAuthenticated: true };
}

function login(state, payload) {
    HTTP.defaults.headers.common["Authorization"] = `Bearer ${payload}`;

    return { ...state };
}

function logout(state) {
    cookies.remove("access_token", {
        path: "/",
    });

    state = Object.assign({}, { isAuthenticated: false }, new User({}));

    return { ...state };
}

export default reducer;
