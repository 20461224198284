import React from "react";
import PropTypes from "prop-types";

import { Layout } from "antd";
import Topbar from "../commom/components/topbar";

const { Content, Footer } = Layout;

const displayName = "Blocked Layout";
const propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object
};

function BlockedLayout({ children, location }) {
  return (
    <Layout>
      <Layout>
        <Topbar />

        <Content className="content-layout">{children}</Content>

        <Footer className="approval-footer">Firstoeat &copy; 2018</Footer>
      </Layout>
    </Layout>
  );
}

BlockedLayout.dispatch = displayName;
BlockedLayout.propTypes = propTypes;

export default BlockedLayout;
