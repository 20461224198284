import Model from '../../util/Model';
import Profile from './Profile';

class User extends Model {

	constructor(props) {
		super(props)

		this.initialize(props);

	}

	initialize(props) {
		super.initialize(props);

        this._id = props._id || '';
		this.email = props.email || '';

        this.group = props.group || 'user';
        this.active = props.active || false;
        this.ppo = props.ppo || 0;

		this.profile = props.profile ? new Profile(props.profile) : new Profile({});

        this.regions = props.regions || [];
        this.rewards = props.rewards || [];
        this.address = props.address || [];
        this.wishlist = props.wishlist || [];
        this.region = props.region || [];
	}
}

export default User
