import * as types from "../types";

const initialState = {
    regions: {
        docs: [],
        page: 1,
        pages: 1,
        limit: 10,
        total: 0
    },
    countries: [],
    unique: [],
    cities: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_REGIONS:
            return { ...state, regions: action.payload };

        case types.GET_ALL_COUNTRIES:
            return { ...state, countries: action.payload };

        case types.GET_CITIES:
            return { ...state, cities: action.payload };

        case types.GET_REGION:
            return { ...state, unique: action.payload };

        default:
            return { ...state };
    }
};

export default reducer;
