
import moment from 'moment'
import _ from 'lodash'

class Model {

	constructor(props) {

		this.initialize(props)
    }

	initialize(props) {

	}
  
	toJson() {

		const props = Object.assign({}, this)
    
		_.forOwn(props, (value, key) => {

			if (value instanceof moment) {
				props[key] = value.format('YYYY-MM-DD HH:mm:ss')
			}
      
		})

		return props
	}
}

export default Model
