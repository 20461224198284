import Loadable from 'react-loadable';

import LoadingComponent from '../../commom/components/loader';

export default [
    {
        path: '/401',
        exact: true,
        auth: true,
        group: ['admin', 'franchisee', 'restaurant', 'user'],
        component: Loadable({
            loader: () => import('./pages/401'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/auth/recovery',
        exact: true,
        component: Loadable({
            loader: () => import('./pages/recovery'),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/auth/reset_password/:token',
        exact: true,
        component: Loadable({
            loader: () => import('./pages/resetPassword'),
            loading: LoadingComponent,
        }),
    },
    {
        exact: true,
        component: Loadable({
            loader: () => import('./pages/login'),
            loading: LoadingComponent,
        }),
    },
]
