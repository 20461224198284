import * as types from "../types";

const initialState = {
    occupation: {},
    restaurants: {},
    access: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_OCCUPATION:
            return { ...state, occupation: action.payload };

        case types.GET_PER_RESTAURANT:
            return { ...state, restaurants: action.payload };

        case types.GET_ACCESS_RESTAURANT:
            return { ...state, access: action.payload };

        default:
            return { ...state };
    }
};

export default reducer;
