import Loadable from "react-loadable";

import LoadingComponent from "../../commom/components/loader";

export default [
    // {
    //     path: "/restaurant/dashboard",
    //     exact: true,
    //     auth: true,
    //     group: ["franchisee", "restaurant"],
    //     component: Loadable({
    //         loader: () => import("./pages/NextReserves"),
    //         loading: LoadingComponent
    //     })
    // },
    {
        path: "/restaurant/next-reserves",
        exact: true,
        auth: true,
        group: ["franchisee", "restaurant"],
        component: Loadable({
            loader: () => import("./pages/NextReserves"),
            loading: LoadingComponent
        })
    },
    {
        path: "/restaurant/all-reserves",
        exact: true,
        auth: true,
        group: ["franchisee", "restaurant"],
        component: Loadable({
            loader: () => import("./pages/AllReserves"),
            loading: LoadingComponent
        })
    },
    {
        path: "/restaurant/edit",
        exact: true,
        auth: true,
        group: ["franchisee", "restaurant"],
        component: Loadable({
            loader: () => import("./pages/EditRestaurant/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/restaurant/reviews",
        exact: true,
        auth: true,
        group: ["franchisee", "restaurant"],
        component: Loadable({
            loader: () => import("./pages/Reviews"),
            loading: LoadingComponent
        })
    },
    {
        path: "/restaurant/reviews/reply/:id",
        exact: true,
        auth: true,
        group: ["franchisee", "restaurant"],
        component: Loadable({
            loader: () => import("./pages/Reviews/reply"),
            loading: LoadingComponent
        })
    },
    {
        path: "/restaurant/assessment/details",
        exact: true,
        auth: true,
        group: ["franchisee", "restaurant"],
        component: Loadable({
            loader: () =>
                import("./pages/LastEvaluations/details/AssessmentDetail"),
            loading: LoadingComponent
        })
    },
    {
        path: "/restaurant/analytics/",
        exact: true,
        auth: true,
        group: ["franchisee", "restaurant"],
        component: Loadable({
            loader: () => import("./pages/Analytics"),
            loading: LoadingComponent
        })
    }
];
