import * as types from "../types";

const initialState = {
    configurations: null,
    unique: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_SYSTEM_CONFIG:
            return { ...state, configurations: action.payload };

        case types.GET_SYSTEM_CONFIG:
            return { ...state, unique: action.payload };

        default:
            return { ...state };
    }
};

export default reducer;
