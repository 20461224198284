import * as types from '../types';

const initialState = {
    data: []
};

 const reducer = (state = initialState, action) => {

    switch (action.type) {

        case types.FETCH_MATERIALS:
            return { ...state, data: action.payload };
        
        default:
            return { ...state };
    }

}

export default reducer;