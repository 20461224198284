
import antdPT from "antd/lib/locale-provider/pt_BR";
import appLocaleData from "react-intl/locale-data/pt";
import ptMessages from "../locales/pt_BR.json";

const ptLang = {
  messages: {
    ...ptMessages
  },
  antd: antdPT,
  locale: 'pt-BR',
  data: appLocaleData
};
export default ptLang;
