import React from "react";
import PropTypes from "prop-types";

import { Layout } from "antd";
import Sidebar from "../commom/components/sidebar";
import Topbar from "../commom/components/topbar";

const { Content, Footer } = Layout;

const displayName = "Private Layout";
const propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object
};

function PrivateLayout({ children, location }) {
  return (
    <Layout>
      <Sidebar location={location} />

      <Layout>
        <Topbar />

        <Content className="content-layout">{children}</Content>

        <Footer>Firstoeat &copy;2018</Footer>
      </Layout>
    </Layout>
  );
}

PrivateLayout.dispatch = displayName;
PrivateLayout.propTypes = propTypes;

export default PrivateLayout;
