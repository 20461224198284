import React, { Component } from "react";
import { Layout } from "antd";
import ContentAdmin from "./ContentAdmin";
import ContentFranchisee from "./ContentFranchisee";
import ContentRestaurant from "./ContentRestaurant";
import { IoMenuSharp, IoCloseSharp } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";

const { Sider } = Layout;

export default class Sidebar extends Component {
    state = {
        openMenu: false,
        isScreenLarge: window.innerWidth >= 1200,
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        this.setState({ isScreenLarge: window.innerWidth >= 1200 });
    };

    toggleCollapse = () => {
        this.setState({ openMenu: !this.state.openMenu });
    };

    renderSidebar = () => {
        const path = this.props.location.pathname.split("/")[1];

        if (path === "admin") return <ContentAdmin match={path} />;
        if (path === "franchisee") return <ContentFranchisee match={path} />;
        return <ContentRestaurant match={path} />;
    };

    render() {
        const { openMenu, isScreenLarge } = this.state;
        return (
            <div style={{ position: "relative", background: "black" }}>
                <Sider
                    style={openMenu || isScreenLarge ? { display: "flex" } : { display: "none" }}
                    className="sidebar"
                    theme="dark"
                    width={280}
                    trigger={null}
                >
                    {this.renderSidebar()}
                </Sider>
                {!isScreenLarge && (
                    <div
                        type="primary"
                        onClick={this.toggleCollapse}
                        className="menu-toggle-button"
                        style={
                            openMenu
                                ? {
                                      margin: '16px',
                                      position: "absolute",
                                      top: "10px",
                                      left: "230px",
                                      zIndex: "1000",
                                      color: "#cecece",
                                      fontSize: "1.6rem",
                                      cursor: "pointer"
                                  }
                                : {
                                      margin: '16px',
                                      position: "absolute",
                                      top: "0",
                                      left: "100%",
                                      zIndex: "1000",
                                      cursor: "pointer",
                                      fontSize: "1.6rem"
                                  }
                        }
                    >
                        {openMenu ? <IoIosCloseCircleOutline/> : <IoMenuSharp />}
                    </div>
                )}
            </div>
        );
    }
}
