import * as types from "../types";

const initialState = {
    data: {
        docs: [],
        limit: 10,
        page: 1,
        pages: 1,
        total: 0
    },
    reserve: null,
    allToday: null,
    allNext: [],
    allUsers: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_RESERVES:
            return { ...state, data: action.payload };

        case types.GET_ALL_ORDERS_TODAY:
            return { ...state, allToday: action.payload };

        case types.GET_ALL_ORDERS_NEXT:
            return { ...state, allNext: action.payload };

        case types.GET_ALL_ORDERS_USERS:
            return { ...state, allUsers: action.payload };

        case types.GET_ORDER_DETAIL:
            return { ...state, reserve: action.payload };

        default:
            return { ...state };
    }
};

export default reducer;
