import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({
    component: Component,
    isAuthenticated,
    group,
    user,
    ...rest
}) => {
    let userGroup = user.group;

    let permited = group.includes(userGroup);

    return (
        <Route
            {...rest}
            render={props => {
                if (isAuthenticated) {
                    if (permited) {
                        return <Component {...props} />;
                    } else {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/401",
                                    state: { from: props.location }
                                }}
                            />
                        );
                    }
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/sigin",
                                state: { from: props.location }
                            }}
                        />
                    );
                }
            }}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    location: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired
};

function mapStateToProps(store) {
    return {
        isAuthenticated: store.user.isAuthenticated,
        user: store.user
    };
}

export default connect(mapStateToProps)(PrivateRoute);
