import axios from "axios";
import store from "../store";

import { authLogout } from "../modules/auth/store/actions";

import config from "../config";

const API_URL = `${config.apiGateway.URL}`;

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common.Accept = "application/json";

console.log(store, "store 1");

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            store.dispatch(authLogout());
        }

        return Promise.reject(error.response);
    }
);

export default axios;
