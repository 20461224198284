import * as types from '../types';

const initialState = {
    data: [],
    regions: [],
    kitchens: [],
    users: [],
    services: [],
    countries: [],
    restaurantId: [],
    cities: []
}

 const reducer = (state = initialState, action) => {

    switch (action.type) {

        case types.FETCH_RESTAURANTS:
            return { ...state, data: action.payload };
        
        case types.GET_REGIONS:
            return { ...state, regions: action.payload };
        
        case types.GET_KITCHENS:
            return { ...state, kitchens: action.payload };
        
        case types.GET_USERS:
            return { ...state, users: action.payload };
        
        case types.GET_SERVICES:
            return { ...state, services: action.payload };
        
        case types.GET_COUNTRIES:
            return { ...state, countries: action.payload };
        
        case types.GET_CITIES:
            return { ...state, cities: action.payload };

        case types.ADD_RESTAURANT:
            return { ...state, data: action.payload };
            
        case types.GET_RESTAURANT_ID:
            return { ...state, restaurantId: action.payload };

        case types.EDIT_RESTAURANT:
            return { ...state, restaurantId: action.payload };
        
        case types.UPLOAD_IMAGE:
            return { ...state, restaurantId: action.payload };
        
        default:
            return { ...state };
    }

}

export default reducer;