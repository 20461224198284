import * as types from "../types";

const initialState = {
    data: {
        docs: [],
        limit: 10,
        total: 0,
        page: 1,
        pages: 1
    },
    unique: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_COUPONS:
            return { ...state, data: action.payload };
        case types.GET_COUPON:
            return { ...state, unique: action.payload };

        default:
            return { ...state };
    }
};

export default reducer;
