import React from "react";
import { Router, Switch } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";

import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";
import AppLocale from "../lngProvider";

import routes from "./routes";
import PrivateRoute from "./Private";
import PublicRoute from "./Public";

import Layout from "../layout";
import config from "../config";

const history = createBrowserHistory();

const locale = {
    languageId: "portugues-brasil",
    locale: "pt",
    name: "Português-BR",
    icon: "br"
};

const currentAppLocale = AppLocale[locale.locale];

const Routes = () => (
    <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            <Router history={history} basename={config.app.BASENAME}>
                <Layout>
                    <Switch>
                        {routes.map((route, i) => {
                            if (route.auth) {
                                return <PrivateRoute key={i} {...route} />;
                            }

                            return <PublicRoute key={i} {...route} />;
                        })}
                    </Switch>
                </Layout>
            </Router>
        </IntlProvider>
    </LocaleProvider>
);

export default Routes;
