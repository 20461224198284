// TYPES MATERIALS
export const FETCH_MATERIALS = "FETCH_MATERIALS";

// TYPES RESERVES
export const FETCH_RESERVES = "FETCH_RESERVES";
export const GET_ALL_ORDERS_TODAY = "GET_ALL_ORDERS_TODAY";
export const GET_ALL_ORDERS_NEXT = "GET_ALL_ORDERS_NEXT";
export const GET_ALL_ORDERS_USERS = "GET_ALL_ORDERS_USERS";
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL";

// TYPES NOTIFICATIONS
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const EDIT_NOTIFICATION = "EDIT_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

// TYPES RESTAURANTS
export const FETCH_RESTAURANTS = "FETCH_RESTAURANTS";
export const GET_RESTAURANT_ID = "GET_RESTAURANT_ID";
export const ADD_RESTAURANT = "ADD_RESTAURANT";
export const EDIT_RESTAURANT = "EDIT_RESTAURANT";
export const REMOVE_RESTAURANT = "REMOVE_RESTAURANT";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";

// TYPES REGIONS
export const GET_REGIONS = "GET_REGIONS";

// TYPES KITCHEN
export const GET_KITCHENS = "GET_KITCHENS";

// TYPES SERVICES
export const GET_SERVICES = "GET_SERVICES";

// TYPES COUNTRIES
export const GET_COUNTRIES = "GET_COUNTRIES";

// TYPES CITIES
export const GET_CITIES = "GET_CITIES";

// TYPES USERS
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER = "GET_USER";
export const GET_USERS = "GET_USERS";
export const GET_ALL_USERS_TODAY = "GET_ALL_USERS_TODAY";
export const GET_USER_BY_REGION = "GET_USER_BY_REGION";
export const GET_USER_BY_REGION_AGGREGATED = "GET_USER_BY_REGION_AGGREGATED";
export const GET_USER_BY_REGION_AGGREGATED_PAST = "GET_USER_BY_REGION_AGGREGATED_PAST";

//TYPES FINANCIAL
export const GET_ORDERS_CURRENT = "GET_ORDERS_CURRENT";
export const GET_ORDERS_PAST = "GET_ORDERS_PAST";

// TYPES ANALYTICS
export const GET_OCCUPATION = "GET_OCCUPATION";
export const GET_PER_RESTAURANT = "GET_PER_RESTAURANT";
export const GET_ACCESS_RESTAURANT = "GET_ACCESS_RESTAURANT";

// TYPES COUPONS
export const GET_ALL_COUPONS = "GET_ALL_COUPONS";
export const GET_COUPON = "GET_COUPON";
