const config = {
    dev: {
        app: {
            URL: "https://localhost:3000/",
            BASEDOMAIN: "localhost",
            BASENAME: "https://localhost:3000/",
            CDN: "https://localhost:3000/"
        },
        apiGateway: {
            URL: "https://evolutionsoft.dev.br:9000/api",

            //"https://localhost:3001/api",
            // URL: "https://api.firstoeat.com/api",
            VERSION: "v1"
        }
    },
    prod: {
        app: {
            URL: "https://panel.firstoeat.com.br/",
            BASEDOMAIN: "firstoeat.com.br",
            BASENAME: "https://panel.firstoeat.com.br/",
            CDN: "https://panel.firstoeat.com.br/"
        },
        apiGateway: {
            URL: "https://evolutionsoft.dev.br:9000/api",
            // URL: "https://api.firstoeat.com/api",
            VERSION: "v1"
        }
    }
};

module.exports = config.prod;
