import * as types from "../types";

const initialState = {
    occupation: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_OCCUPATION:
            return { ...state, occupation: action.payload };

        default:
            return { ...state };
    }
};

export default reducer;
