import React from 'react';
import { render } from 'react-dom';
import {Provider} from 'react-redux';

import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';

import './assets/vendors/style';
import './styles/flexile.less';

render( 
    (
        <Provider store={store}>
            <App/>
        </Provider>
    ),

document.getElementById('root'));

if (module.hot) {
    
    module.hot.accept('./App', () => {
    
        render(App);

    });
}

serviceWorker.unregister();
