import React from 'react';

const Loading = ( {local} ) => {

    return ( 
        
        <div className={`loading ${ local ? local : ''}`}>

            <div className='lds-ellipsis'><div></div><div></div><div></div><div></div></div>
            
        </div> 
    )
}

export default Loading;