import * as types from "./action-types";

export const authCheck = () => {
    return {
        type: types.AUTH_CHECK
    };
};

export const authLogin = payload => {
    return {
        type: types.AUTH_LOGIN,
        payload
    };
};

export const authLogout = () => {
    return {
        type: types.AUTH_LOGOUT
    };
};

export const authUser = payload => {
    return {
        type: types.AUTH_USER,
        payload
    };
};
