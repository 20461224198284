const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'portugues-brasil',
    locale: 'pt',
    name: 'Português-BR',
    icon: 'br'
  }
];
export default languageData;
