import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchUser } from '../modules/auth/service';

import PrivateLayout from './Private';
import PublicLayout from './Public';
import BlockedLayout from './Blocked';
import Loader from '../commom/components/loader';

class Layout extends Component {
	static displayName = 'Layout';

	static propTypes = {
		isAuthenticated: PropTypes.bool.isRequired,
		user: PropTypes.object.isRequired,
		children: PropTypes.node.isRequired,
		dispatch: PropTypes.func.isRequired
	};

	componentDidMount() {
		const { isAuthenticated, user } = this.props;

		if (isAuthenticated && user._id === '') {
			this.props.dispatch(fetchUser());
		}
	}

	render() {
		const { isAuthenticated, children, user, ...props } = this.props;

		if (isAuthenticated) {

			if (user._id === '') {

				return <Loader />
			}

			if (user.group === 'restaurant' && (user.restaurant === undefined || !user.restaurant.show)) {
				return <BlockedLayout {...props}>{children}</BlockedLayout>;
			}

			return <PrivateLayout {...props}>{children}</PrivateLayout>;
		}

		return <PublicLayout {...props}>{children}</PublicLayout>;
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.user.isAuthenticated,
		user: state.user,
		blocked: false
	};
};

export default withRouter(connect(mapStateToProps)(Layout));
