import Http from "../../util/Http";
import * as actions from "./store/actions";

/**
 * fetch the current logged in user
 *
 * @returns {function(*)}
 */
export function fetchUser() {
    return dispatch =>
        new Promise((resolve, reject) => {
            return Http.get("/identity")
                .then(res => {
                    if (res.data.data.group === "user") return reject();


                    dispatch(actions.authUser(res.data.data));
                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
}

export function fetchEmail() {
    return dispatch =>
        new Promise((resolve, reject) => {
            return Http.get("/identity")
                .then(res => {
                    if (res.data.data.group === "user") return reject();


                    dispatch(actions.authUser(res.data.data));

                    const {email} = res.data.data

                    return resolve({email});
                })
                .catch(err => {
                    return reject(err);
                });
        });
}

/**
 * login user
 *
 * @param credentials
 * @returns {function(*)}
 */
export function login(credentials) {
    return dispatch =>
        new Promise((resolve, reject) => {
            Http.post("/auth/", credentials)
                .then(res => {
                    if (res.data.success) {
                        dispatch(actions.authLogin(res.data.data.token));

                        return resolve();
                    }
                })
                .catch(err => {
                    let errorMessage = "Invalid credentials";
                    let statusCode = 401;

                    return reject({ errorMessage, statusCode });
                });
        });
}

/**
 * logout user
 *
 * @returns {function(*)}
 */
export function logout() {
    return dispatch => {
        return dispatch(actions.authLogout());
    };
}

/**
 * forgotPassword
 *
 * @param email
 * @returns {function(*)}
 */
export function forgotPassword(email) {
    return dispatch =>
        new Promise((resolve, reject) => {
            Http.post("/auth/forgot_password", email)
                .then(res => resolve(res.data.data))
                .catch(err => {
                    const errorMessage = err.data.message;
                    return reject(errorMessage);
                });
        });
}

/**
 * Change password to reset
 *
 * @param password
 * @returns {function(*)}
 */
export function changeForgotPassword(data) {
    return dispatch =>
        new Promise((resolve, reject) => {
            Http.post("/auth/reset_password", data)
                .then(res => resolve(res.data.data))
                .catch(err => {
                    const errorMessage = err.data.message;
                    return reject(errorMessage);
                });
        });
}
