import React, { Fragment } from "react";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";

import IntlMessages from "util/IntlMessages";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

export default class SidebarContent extends React.Component {
    render() {
        return (
            <Fragment>
                <div className="logo">
                    <img
                        src={require("assets/images/logo.png")}
                        alt="Firstoeat"
                    />
                </div>

                <Menu
                    defaultOpenKeys={[this.props.match]}
                    theme="dark"
                    mode="inline"
                    className="scrollList"
                >
                    <MenuItemGroup
                        key="menu"
                        title={<IntlMessages id="sidebar.menu" />}
                    >
                        <Menu.Item key="main">
                            <Link to="/franchisee/dashboard">
                                <Icon type="dashboard" />
                                <IntlMessages id="sidebar.main" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="analytcs">
                            <Link to="/franchisee/analytics">
                                <Icon type="area-chart" />
                                <IntlMessages id="sidebar.analytics" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="financial">
                            <Link to="/franchisee/financial">
                                <Icon type="credit-card" />
                                <IntlMessages id="sidebar.financial" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="reserves">
                            <Link to="/franchisee/reserves">
                                <Icon type="tags" />
                                <IntlMessages id="sidebar.reserves" />
                            </Link>
                        </Menu.Item>
                    </MenuItemGroup>

                    {/* <SubMenu
                        key="notifications"
                        title={
                            <div>
                                <Icon type="file-text" />
                                <IntlMessages id="sidebar.notifications.menu" />
                            </div>
                        }
                    >
                        <Menu.Item key="select_notifications">
                            <Link to="/franchisee/notifications/list">
                                <Icon type="bars" />
                                <IntlMessages id="sidebar.notifications.submenu.select" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="create_notifications">
                            <Link to="/franchisee/notifications/add">
                                <Icon type="plus-circle" />
                                <IntlMessages id="sidebar.notifications.submenu.create" />
                            </Link>
                        </Menu.Item>
                    </SubMenu> */}

                    <SubMenu
                        key="coupons"
                        title={
                            <div>
                                <Icon type="file-text" />
                                <IntlMessages id="sidebar.tickets.menu" />
                            </div>
                        }
                    >
                        <Menu.Item key="select_coupons">
                            <Link to="/franchisee/coupons/list">
                                <Icon type="bars" />
                                <IntlMessages id="sidebar.tickets.submenu.select" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="create_coupons">
                            <Link to="/franchisee/coupons/add">
                                <Icon type="plus-circle" />
                                <IntlMessages id="sidebar.tickets.submenu.create" />
                            </Link>
                        </Menu.Item>
                    </SubMenu>

                    <SubMenu
                        key="restaurants"
                        title={
                            <div>
                                <Icon type="shop" />
                                <IntlMessages id="sidebar.restaurants.menu" />
                            </div>
                        }
                    >
                        {/* <SubMenu key="submenu_restaurants" title={<div><Icon type="shop" /><IntlMessages id="sidebar.restaurants.submenu.restaurants"/></div>} > */}

                        <Menu.Item key="select_restaurants">
                            <Link to="/franchisee/restaurants/list">
                                <Icon type="bars" />
                                <IntlMessages id="sidebar.restaurants.submenu.restaurants.list" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="create_restaurants">
                            <Link to="/franchisee/restaurants/add">
                                <Icon type="plus-circle" />
                                <IntlMessages id="sidebar.restaurants.submenu.restaurants.add" />
                            </Link>
                        </Menu.Item>

                        {/* <Menu.Item key="continueRegister">
                            <Link to="/franchisee/continue-register/">
                                <Icon type="edit" />
                                Continuar cadastro
                            </Link>
                        </Menu.Item> */}

                        {/* </SubMenu> */}

                        {/* <SubMenu key="submenu_galery" title={<div><Icon type="camera" /><IntlMessages id="sidebar.restaurants.submenu.galery"/></div>} >

                            <Menu.Item key="select_galery">
                                <Link to="/franchisee/galery/list"><Icon type="bars" />
                                    <IntlMessages id="sidebar.restaurants.submenu.galery.list"/></Link>
                            </Menu.Item>

                            <Menu.Item key="create_galery">
                                <Link to="/franchisee/galery/add"><Icon type="plus-circle" />
                                    <IntlMessages id="sidebar.restaurants.submenu.galery.add"/></Link>
                            </Menu.Item>

                        </SubMenu> */}

                        {/* <SubMenu key="submenu_calendar" title={<div><Icon type="calendar" /><IntlMessages id="sidebar.restaurants.submenu.calendar"/></div>} >

                            <Menu.Item key="select_restaurants">
                                <Link to="/franchisee/calendar/list"><Icon type="bars" />
                                    <IntlMessages id="sidebar.restaurants.submenu.calendar.list"/></Link>
                            </Menu.Item>

                            <Menu.Item key="create_restaurants">
                                <Link to="/franchisee/calendar/add"><Icon type="plus-circle" />
                                    <IntlMessages id="sidebar.restaurants.submenu.calendar.add"/></Link>
                            </Menu.Item>

                        </SubMenu> */}

                        {/* <SubMenu key="submenu_featured" title={<div><Icon type="star" /><IntlMessages id="sidebar.restaurants.submenu.featured"/></div>} >

                            <Menu.Item key="select_featured">
                                <Link to="/franchisee/featured/list"><Icon type="bars" />
                                    <IntlMessages id="sidebar.restaurants.submenu.featured.list"/></Link>
                            </Menu.Item>

                            <Menu.Item key="create_featured">
                                <Link to="/franchisee/featured/add"><Icon type="plus-circle" />
                                    <IntlMessages id="sidebar.restaurants.submenu.featured.add"/></Link>
                            </Menu.Item>

                        </SubMenu> */}
                    </SubMenu>

                    {/* <Menu.Item key="assessments">
                        <Link to="/franchisee/assessments/list">
                            <Icon type="check-square" />
                            <IntlMessages id="sidebar.assessments" />
                        </Link>
                    </Menu.Item> */}

                    {/* <SubMenu
            key="users"
            title={
              <div>
                <Icon type="user" />
                <IntlMessages id="sidebar.users.menu" />
              </div>
            }
          >
            <Menu.Item key="select_users">
              <Link to="/franchisee/users/list">
                <Icon type="bars" />
                <IntlMessages id="sidebar.users.submenu.select" />
              </Link>
            </Menu.Item>

            <Menu.Item key="statistics_users">
              <Link to="/franchisee/users/statistics">
                <Icon type="bar-chart" />
                <IntlMessages id="sidebar.users.submenu.statistics" />
              </Link>
            </Menu.Item>
          </SubMenu> */}
                </Menu>
            </Fragment>
        );
    }
}
