
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const displayName = 'Public Layout'

const propTypes = {
	children: PropTypes.node.isRequired,
	location: PropTypes.object
}

function PublicLayout({ children, location }) {

	return (
		<Fragment>

			<main>
				{ children }
			</main>

		</Fragment>
	)
}

PublicLayout.dispatch = displayName
PublicLayout.propTypes = propTypes

export default PublicLayout
