import * as types from '../types';

const initialState = {
    all: {
        docs: [],
        pages: 1,
        page: 1,
        limit: 10,
    },
    next: {
        docs: [],
        pages: 1,
        page: 1,
        limit: 10
    },
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case types.GET_ALL:
            return { ...state, all: action.payload };

        case types.GET_NEXT:
            return { ...state, next: action.payload };

        default:
            return { ...state };
    }
}

export default reducer;
