import * as types from "../types";

const initialState = {
    data: {
        docs: [],
        page: 1,
        pages: 1,
        limit: 10,
        total: 0
    },
    unique: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_USE_TERMS:
            const unique = action.payload.docs.length ? action.payload.docs[0] : null;
            return { ...state, data: action.payload, unique };

        case types.GET_USE_TERM:
            return { ...state, unique: action.payload };

        default:
            return { ...state };
    }
};

export default reducer;
