/**
 * Types of orders
 */

export const GET_ORDERS = "GET_ORDERS"

// TYPES MATERIALS
export const FETCH_MATERIALS = 'FETCH_MATERIALS';

// TYPES RESERVES
export const GET_ALL = 'GET_ALL';
export const GET_NEXT = 'GET_NEXT';

// TYPES TICKETS
export const GET_ALL_TICKETS = 'GET_ALL_TICKETS';
export const EDIT_TICKET = 'EDIT_TICKET';
export const REMOVE_TICKET = 'REMOVE_TICKET';

// TYPES RESTAURANTS
export const FETCH_RESTAURANTS = 'FETCH_RESTAURANTS';
export const GET_RESTAURANT_ID = 'GET_RESTAURANT_ID';
export const ADD_RESTAURANT = 'ADD_RESTAURANT';
export const EDIT_RESTAURANT = 'EDIT_RESTAURANT';
export const REMOVE_RESTAURANT = 'REMOVE_RESTAURANT';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';

// TYPES REGIONS
export const GET_REGIONS = 'GET_REGIONS';

// TYPES KITCHEN
export const GET_KITCHENS = 'GET_KITCHENS';

// TYPES SERVICES
export const GET_SERVICES = 'GET_SERVICES';

// TYPES COUNTRIES
export const GET_COUNTRIES = 'GET_COUNTRIES';

// TYPES CITIES
export const GET_CITIES = 'GET_CITIES';

// TYPES USERS
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';

export const FETCH_REVIEWS = 'FETCH_REVIEWS';
export const GET_REVIEW = 'GET_REVIEW';


export const GET_OCCUPATION = "GET_OCCUPATION";

