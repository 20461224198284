import React, { Fragment } from "react";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import Http from "util/Http";

import IntlMessages from "util/IntlMessages";
import { fetchUser2 } from "~/modules/auth/service";

const MenuItemGroup = Menu.ItemGroup;

export default class ContentRestaurant extends React.Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         id: ""
    //     };
    // }

    // async componentDidMount() {
    //     try {
    //         const res = await Http.get("/identity");
    //         console.log(res.data.data);

    //         this.setState({ id: res.data.data.restaurant.id });
    //         return res.data.data; // Retorna os dados se tudo der certo
    //     } catch (error) {
    //         throw error; // Propaga o erro para quem chamar essa função
    //     }
    // }
    render() {
        return (
            <Fragment>
                <div className="logo">
                    <img
                        src={require("assets/images/logo.png")}
                        alt="Firstoeat"
                    />
                </div>

                <Menu
                    defaultOpenKeys={[this.props.match]}
                    theme="dark"
                    mode="inline"
                    className="scrollList"
                >
                    <MenuItemGroup
                        key="menu"
                        title={<IntlMessages id="sidebar.menu" />}
                    >
                        {/* <Menu.Item key="main">
                            <Link to="/restaurant/dashboard">
                                <Icon type="dashboard" />
                                <IntlMessages id="sidebar.menu.restaurant.main" />
                            </Link>
                        </Menu.Item> */}

                        <Menu.Item key="next_reservas">
                            <Link to="/restaurant/analytics">
                                <Icon type="area-chart" />
                                <IntlMessages id="sidebar.menu.restaurant.analytics" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="all_reserves">
                            <Link to="/restaurant/all-reserves">
                                <Icon type="tags" />
                                <IntlMessages id="sidebar.menu.restaurant.all_reserves" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="edit_data">
                            <Link to={`/restaurant/edit`}>
                                <Icon type="edit" />
                                <IntlMessages id="sidebar.menu.restaurant.edit_data" />
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="assessments">
                            <Link to="/restaurant/reviews">
                                <Icon type="check-square" />
                                <IntlMessages id="sidebar.menu.restaurant.reviews" />
                            </Link>
                        </Menu.Item>
                    </MenuItemGroup>
                </Menu>
            </Fragment>
        );
    }
}
