import Loadable from "react-loadable";

import LoadingComponent from "../../commom/components/loader";

export default [
    {
        path: "/franchisee/dashboard",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Dashboard"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/analytics",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Analytics"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/financial",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Financial"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/materials",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Materials"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/reserves",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Reserves"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/reserves/view",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Reserves"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/reserves/refund/:id",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Reserves/refund"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/notifications/add",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Notifications/add"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/notifications/list",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Notifications/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/notifications/edit/:id",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Notifications/edit"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/coupons/list",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Coupons/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/coupons/add",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Coupons/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/coupons/edit/:id",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Coupons/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/restaurants/list",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Restaurants/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/restaurants/add",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Restaurants/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/restaurants/edit/:id",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Restaurants/form"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/restaurants/reviews/:id",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Restaurants/reviews"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/galery/list",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/GaleryPhotos/ListGalery"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/galery/add",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/GaleryPhotos/FormGalery"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/galery/edit/:id",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/GaleryPhotos/FormGalery"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/calendar/list",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Calendar/ListCalendar"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/calendar/add",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Calendar/FormCalendar"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/calendar/edit/:id",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Calendar/FormCalendar"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/featured/list",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Featured/ListFeatured"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/featured/add",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Featured/FormFeatured"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/featured/edit/:id",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Featured/FormFeatured"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/assessments/list",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Assessments/ListAssessments"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/assessments/details",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Assessments/AssessmentDetail"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/assessments/edit/:id",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Assessments/ListAssessments"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/users/",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Users/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/users/list",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Users/list"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/users/edit/:id",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Users/edit"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/continue-register",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/ContinueRegister"),
            loading: LoadingComponent
        })
    },
    {
        path: "/franchisee/users/statistics",
        exact: true,
        auth: true,
        group: ["admin", "franchisee"],
        component: Loadable({
            loader: () => import("./pages/Users/TableStatistics"),
            loading: LoadingComponent
        })
    }
];
