import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";

import Loading from "./commom/components/loading";
import Routes from "./routes";

import { authCheck } from "./modules/auth/store/actions";
import { fetchUser } from "./modules/auth/service";

import Cookies from "universal-cookie";

const cookies = new Cookies();

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };

        this.props.dispatch(authCheck());
    }

    async componentDidMount() {
        const { loading } = this.state;
        const { dispatch } = this.props;

        const isAuth = !!cookies.get("access_token");

        if (loading && isAuth) await dispatch(fetchUser());

        this.setState({ loading: false });
    }

    render() {
        return this.state.loading ? (
            <Loading local="start-app" />
        ) : (
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.user.isAuthenticated
});

export default connect(mapStateToProps)(App);
