import * as types from '../types';

const initialState = {
    data: [],
    unique: {}
};

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case types.FETCH_REVIEWS:
            return { ...state, data: action.payload };

        case types.GET_REVIEW:
            return { ...state, unique: action.payload };

        default:
            return { ...state };
    }

}

export default reducer;
