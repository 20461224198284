// auth action types
export const AUTH_CHECK = 'AUTH_CHECK'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_USER = 'AUTH_USER'

export default {
	AUTH_CHECK,
	AUTH_LOGIN,
	AUTH_LOGOUT,
	AUTH_USER
}